@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.form {
  display: flex;
  flex-direction: column;
  width: 250px;
  justify-content: space-between;

  .scCheck {
    transform: scale(1);
  }

  .formControl {
    margin: 0;
    margin-bottom: 16px;
  }

  .bottom {
    margin-bottom: 16px;
    width: max-content;
    padding: 0;
  }

  p {
    position: relative !important;
    bottom: 0;
    left: 0;
    transform: none !important;
  }
}
